import React, { useState, useEffect } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { Plugins } from '@capacitor/core';
import LoginModal from '../components/LoginModal';

import './AfterHours.css';
import GoogleMap from '../components/GoogleMap';

const { Storage } = Plugins;

const AfterHours: React.FC = () => {
  const [user, setUser] = useState();
  const [complete, setComplete] = useState();

  useEffect(() => {
    // Look for user in local storage
    async function checkUser() {
      await Storage.get({ key: 'user' })
        .then((res: any) => {
          setUser(res.value);
          setComplete(true);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }

    checkUser();
  }, [user]);

  return (
    <IonPage className="after-hours-page">
      <IonHeader>
        <IonToolbar>
          <IonTitle>After Hours</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <p className="intro">There once was a thousand young heads</p>
        <p className="intro">Set loose from their purple cow sheds</p>
        <p className="intro">All the day they had learned</p>
        <p className="intro">So a drink they had earned</p>
        <p className="intro">Time for Guinness and top secret celebs <span role="img" aria-labelledby="emoji">☘️</span></p>
        <p className="intro">#1000craics</p>
        <div className="map">
          <GoogleMap 
            location={{
              lat: 51.512002,
              lng: -0.127569
            }} />
          <div className="address">
            <p className="bold">Covent Garden Social Club</p>
            <p>6-7 Great Newport Street, Covent Garden, WC2H 7JB</p>
          </div>
          <div className="directions">
            <a href="https://g.page/covent-garden-social-club?share">Get directions</a>
          </div>
        </div>
        {complete && <LoginModal show={!user ? true : false} />}
      </IonContent>
    </IonPage>
  );
};

export default AfterHours;
