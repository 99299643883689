import React from 'react';

import './NoInternet.css';

const NoInternet: React.FC = () => {
  return (
    <div className="nointernet">
      <img src="assets/images/internet.png" alt="No Internet meme" />
      <h4>Failed to load results, please check your Internet connection.</h4>
    </div>
  );
};

export default NoInternet;
