import React, { useState, useEffect } from 'react';
import { IonSpinner, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCard, IonCardContent } from '@ionic/react';
import { Plugins } from '@capacitor/core';
import LoginModal from '../components/LoginModal';
import NoInternet from '../components/NoInternet';

import { format, parseISO } from "date-fns";
import axios from 'axios';

/* Custom */
import './Schedule.css';

import { apiKey, baseId } from '../config.js';

const { Storage, Network } = Plugins;

const headers = (method: any, url: string, timeout: number = 5000) => {
  return {
    method: method,
    headers: {
      Authorization: `Bearer ${apiKey}`
    },
    url: url,
    timeout: timeout
  }
};

const morning = [
  {
    start: '11:00',
    end: '12:05'
  }
];

const afternoon = [
  {
    start: '12:45',
    end: '1:50'
  },
  {
    start: '1:55',
    end: '3:00'
  },
  {
    start: '3:15',
    end: '4:20'
  }
];

const networkHandler = Network.addListener('networkStatusChange', (status) => {
  console.log("Network status changed", status);
});

const Schedule: React.FC = () => {
  const [complete, setComplete] = useState();
  const [schedule, setSchedule] = useState();
  const [sessions, setSessions] = useState();
  const [user, setUser] = useState();
  const [team, setTeam] = useState();
  const [loading, setLoading] = useState(true);
  const [timeout, setTimeout] = useState(false);

  useEffect(() => {
    // Look for user in local storage
    async function checkUser() {
      await Storage.get({ key: 'user' })
        .then((res: any) => {
          setUser(res.value);
          setComplete(true);
        })
        .catch((err) => {
          console.log(err);
          setComplete(true);
          setTimeout(true);
        });
    }

    // Look for team in local storage
    async function checkTeam() {
      await Storage.get({ key: 'team' })
        .then((res: any) => {
          setTeam(res.value);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // Get the global schedule
    async function getSchedule() {
      setLoading(true);

      await axios(headers('get', `https://api.airtable.com/v0/${baseId}/schedule`, 10000))
        .then(schedule => {
          setSchedule(schedule.data.records.sort((a: any, b: any) => a.fields.start > b.fields.start ? 1 : -1));
          setLoading(false);
          setTimeout(false);
        })
        .catch(err => {
          setTimeout(true);
          setLoading(false);
          console.log(err);
        });
    }

    // Get sessions based on local user team
    async function getSessions() {
      setLoading(true);
      
      complete && team && await axios(headers('get', `https://api.airtable.com/v0/${baseId}/sessions/${team}`, 5000))
        .then((sessions: any) => {
          sessions.data && axios.all([
            axios(headers('get', `https://api.airtable.com/v0/${baseId}/sessions/${sessions.data.fields.sessionone}`, 5000)),
            axios(headers('get', `https://api.airtable.com/v0/${baseId}/sessions/${sessions.data.fields.sessiontwo}`, 5000)),
            axios(headers('get', `https://api.airtable.com/v0/${baseId}/sessions/${sessions.data.fields.sessionthree}`, 5000)),
            axios(headers('get', `https://api.airtable.com/v0/${baseId}/sessions/${sessions.data.fields.sessionfour}`, 5000))
          ])
            .then(axios.spread((one, two, three, four) => {
              let mySessions = {
                one: one.data,
                two: two.data,
                three: three.data,
                four: four.data
              };

              setSessions(mySessions);
              setLoading(false);
              setTimeout(false);
            }))
            .catch(err => {
              setLoading(false);
              setTimeout(true);
              console.log(err);
            });
        })
        .catch(err => {
          setLoading(false);
          setTimeout(true);
          console.log(err);
        });
    }

    checkUser();
    checkTeam();
    getSchedule();
    getSessions();
  }, [user, team, complete]);

  return (
    <IonPage className="schedule-page">
      <IonHeader>
        <IonToolbar class="no-border">
          <IonTitle>My Schedule</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {!loading && timeout && <NoInternet />}
        {!timeout && loading && <IonSpinner name="dots" />}
        {!timeout && !loading && user && team && <div className="schedule">
          {schedule && schedule
            .slice(0, 5)
            .map((item: any, index: number) => {
              return (
                <IonCard key={item.id}>
                  <IonCardContent>
                    <h3>{format(parseISO(item.fields.start), 'h:mm')}am - {format(parseISO(item.fields.end), 'h:mm')}am</h3>
                    <p className="bold">{item.fields.name}</p>
                    {item.fields.details && <p className="details">{item.fields.details}</p>}
                    {item.fields.location && <p className="time"><img src="assets/images/map-location.png" alt="Location" /> {item.fields.location}</p>}
                    {item.fields.presenter && <p className="time"><img src="assets/images/microphone.png" alt="Presenter" /> {item.fields.presenter}</p>}
                  </IonCardContent>
                </IonCard>
              )
            }
            )}
          {!timeout && !loading && user && team && <IonCard>
            <IonCardContent>
              <h3>{morning[0].start}am - {morning[0].end}pm</h3>
              <p className="bold">{sessions && sessions.one.fields.name}</p>
              {sessions && sessions.one.fields.details && <p className="details">{sessions && sessions.one.fields.details}</p>}
              {sessions && sessions.one.fields.location && <p className="time"><img src="assets/images/map-location.png" alt="Location" /> {sessions && sessions.one.fields.location}</p>}
              {sessions && sessions.one.fields.presenter && <p className="time"><img src="assets/images/microphone.png" alt="Presenter" /> {sessions && sessions.one.fields.presenter}</p>}
            </IonCardContent>
          </IonCard>}
          {!timeout && schedule && schedule
            .slice(5, 6)
            .map((item: any, index: number) => {
              return (
                <IonCard key={item.id}>
                  <IonCardContent>
                    <h3>{format(parseISO(item.fields.start), 'h:mm')}pm - {format(parseISO(item.fields.end), 'h:mm')}pm</h3>
                    <p className="bold">{item.fields.name}</p>
                    {item.fields.details && <p className="details">{item.fields.details}</p>}
                    {item.fields.location && <p className="time"><img src="assets/images/map-location.png" alt="Location" /> {item.fields.location}</p>}
                    {item.fields.presenter && <p className="time"><img src="assets/images/microphone.png" alt="Presenter" /> {item.fields.presenter}</p>}
                  </IonCardContent>
                </IonCard>
              )
            }
            )}
          {!timeout && !loading && user && team && <IonCard>
            <IonCardContent>
              <h3>{afternoon[0].start}pm - {afternoon[0].end}pm</h3>
              <p className="bold">{sessions && sessions.two.fields.name}</p>
              {sessions && sessions.two.fields.details && <p className="details">{sessions && sessions.two.fields.details}</p>}
              {sessions && sessions.two.fields.location && <p className="time"><img src="assets/images/map-location.png" alt="Location" /> {sessions && sessions.two.fields.location}</p>}
              {sessions && sessions.two.fields.presenter && <p className="time"><img src="assets/images/microphone.png" alt="Presenter" /> {sessions && sessions.two.fields.presenter}</p>}
            </IonCardContent>
          </IonCard>}
          {!timeout && !loading && user && team && <IonCard>
            <IonCardContent>
              <h3>{afternoon[1].start}pm - {afternoon[1].end}pm</h3>
              <p className="bold">{sessions && sessions.three.fields.name}</p>
              {sessions && sessions.three.fields.details && <p className="details">{sessions && sessions.three.fields.details}</p>}
              {sessions && sessions.three.fields.location && <p className="time"><img src="assets/images/map-location.png" alt="Location" /> {sessions && sessions.three.fields.location}</p>}
              {sessions && sessions.three.fields.presenter && <p className="time"><img src="assets/images/microphone.png" alt="Presenter" /> {sessions && sessions.three.fields.presenter}</p>}
            </IonCardContent>
          </IonCard>}
          {!timeout && schedule && schedule
            .slice(6, 7)
            .map((item: any, index: number) => {
              return (
                <IonCard key={item.id}>
                  <IonCardContent>
                    <h3>{format(parseISO(item.fields.start), 'h:mm')}pm - {format(parseISO(item.fields.end), 'h:mm')}pm</h3>
                    <p className="bold">{item.fields.name}</p>
                    {item.fields.details && <p className="details">{item.fields.details}</p>}
                    {item.fields.location && <p className="time"><img src="assets/images/map-location.png" alt="Location" /> {item.fields.location}</p>}
                    {item.fields.presenter && <p className="time"><img src="assets/images/microphone.png" alt="Presenter" /> {item.fields.presenter}</p>}
                  </IonCardContent>
                </IonCard>
              )
            }
            )}
          {!loading && user && team && <IonCard>
            <IonCardContent>
              <h3>{afternoon[2].start} - {afternoon[2].end}</h3>
              <p className="bold">{sessions && sessions.four.fields.name}</p>
              {sessions && sessions.four.fields.details && <p className="details">{sessions && sessions.four.fields.details}</p>}
              {sessions && sessions.four.fields.location && <p className="time"><img src="assets/images/map-location.png" alt="Location" /> {sessions && sessions.four.fields.location}</p>}
              {sessions && sessions.four.fields.presenter && <p className="time"><img src="assets/images/microphone.png" alt="Presenter" /> {sessions && sessions.four.fields.presenter}</p>}
            </IonCardContent>
          </IonCard>}
          {!timeout && schedule && schedule
            .slice(7, 10)
            .map((item: any, index: number) => {
              return (
                <IonCard key={item.id}>
                  <IonCardContent>
                    <h3>{format(parseISO(item.fields.start), 'h:mm')}pm - {format(parseISO(item.fields.end), 'h:mm')}pm</h3>
                    <p className="bold">{item.fields.name}</p>
                    {item.fields.details && <p className="details">{item.fields.details}</p>}
                    {item.fields.location && <p className="time"><img src="assets/images/map-location.png" alt="Location" /> {item.fields.location}</p>}
                    {item.fields.presenter && <p className="time"><img src="assets/images/microphone.png" alt="Presenter" /> {item.fields.presenter}</p>}
                  </IonCardContent>
                </IonCard>
              )
            }
            )}
        </div>}
        {complete && <LoginModal show={!user ? true : false} />}
      </IonContent>
    </IonPage>
  );
};

export default Schedule;
