import React, { useState, useEffect } from 'react';
import { IonSpinner, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonIcon, IonButton, IonInput, IonTextarea, IonSlides, IonSlide } from '@ionic/react';
import { Plugins } from '@capacitor/core';
import LoginModal from '../components/LoginModal';
import NoInternet from '../components/NoInternet';
import { pencil, checkmark } from 'ionicons/icons';

import Airtable from 'airtable';
import Unsplash from 'unsplash-js';
import axios from 'axios';

import './Personality.css';

import { apiKey, baseId, unsplashKey } from '../config.js';

const { Storage } = Plugins;

const unsplash = new Unsplash({ accessKey: `${unsplashKey}` });
const base = new Airtable({ apiKey: apiKey }).base(baseId);

const headers = (method: any, url: string, timeout: number = 5000) => {
  return {
    method: method,
    headers: {
      Authorization: `Bearer ${apiKey}`
    },
    url: url,
    timeout: timeout
  }
};

const checkFields = (field: string) => {
  return field.length > 0 ? true : false;
}

const saveManual = (setErrors: any, setEditing: any, user: any, content: any) => {
  let errors: boolean[] = [];

  content && content.forEach((c: any, index: number) => {
    if (index < 3) {
      !checkFields(c.data ? c.data.fields.headline : c.fields.headline) && errors.push(true);
      !checkFields(c.data ? c.data.fields.description : c.fields.description) && errors.push(true);
    }
  });

  setErrors(errors);

  errors.length === 0 && setEditing(false);

  errors.length === 0 && content && content.map((c: any) => {
    return c.fields.description.length > 0 && c.id ? base('manuals').update([{
        id: c.id,
        fields: {
          slug: c.fields.headline.replace(/ /g, "-").toLowerCase(),
          headline: c.fields.headline,
          description: c.fields.description,
          order: c.fields.order,
          owner: [user]
        }
      }], (err: any, updated: []) => {
        if (err) return;

        updated.forEach((r: any) => {
          content[r.fields.order-1] = r._rawJson;
        });
      }) : 
      c.fields.description.length > 0 ? base('manuals').create([{
        fields: {
          slug: c.fields.headline.replace(/ /g, "-").toLowerCase(),
          headline: c.fields.headline,
          description: c.fields.description,
          order: c.fields.order,
          owner: [user]
        }
      }]).then((res: any) => content[res.fields.order-1] = res._rawJson).catch(err => console.error(err)) : 
      c.fields.order > 3 && c.id && base('manuals').destroy([`${c.id}`], (err: any, deleted: []) => {
        if (err) return;

        let item = content.findIndex((obj: any) => obj.id === c.id);
        content[item] = defaultContent(item + 1, user);
      });
  });
}

const Slide = (params: any) => {
  return (
    <IonSlide key={params.item.fields.order}>
      <div key={params.item.fields.order} className="input-block">
        <p className="section">
          Section {params.item.fields.order} {!params.required && <span>OPTIONAL</span>}
        </p>
        <p>Headline</p>
        <IonInput
          name={`headline-${params.item.fields.order}`}
          inputmode="text"
          type="text"
          required={params.required ? true : false}
          value={params.headline ? params.headline : ''}
          onIonChange={
            (e: any) => {
              params.content[params.item.fields.order - 1].fields.headline = e.target.value;
            }}
        />
        <p>Description</p>
        <IonTextarea
          name={`description-${params.item.fields.order}`}
          required={params.required ? true : false}
          autoGrow={true}
          value={params.description ? params.description : ''}
          onIonChange={
            (e: any) => {
              params.content[params.item.fields.order - 1].fields.description = e.target.value;
            }}
        />
      </div>
    </IonSlide>
  )
};

const SaveSlide = (data: any) => {
  return (
    <IonSlide>
      <div className="input-block">
        <IonButton className="solo"
          onClick={(e: any) => { saveManual(data.setErrors, data.setEditing, data.user, data.content) }}
        >Save</IonButton>
      </div>
    </IonSlide>
  )
};

const defaultContent = (order: number, owner: string) => {
  return ({
    fields: {
      order: order,
      headline: '',
      description: '',
      owner: [owner]
    }
  });
};

const fillContents = (user: any) => {
  let sections = [];

  for (let s = 0; s < 5; s++) {
    sections.push(defaultContent(s + 1, user));
  }

  return sections;
}

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  autoHeight: true
};

const Personality: React.FC = () => {
  const [user, setUser] = useState();
  const [complete, setComplete] = useState();
  const [editing, setEditing] = useState(false);
  const [type, setType] = useState();
  const [adjectives, setAdjectives] = useState();
  const [description, setDescription] = useState();
  const [content, setContent] = useState();
  const [errors, setErrors] = useState();
  const [manuals, setManuals] = useState();
  const [image, setImage] = useState();
  const [colour, setColour] = useState();
  const [loading, setLoading] = useState(true);
  const [timeout, setTimeout] = useState(false);

  useEffect(() => {
    // Look for user in local storage
    async function checkUser() {
      await Storage.get({ key: 'user' })
        .then((res: any) => {
          setUser(res.value);
          setComplete(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // Get user type
    async function getType() {
      complete && await axios(headers('get', `https://api.airtable.com/v0/appfp4ExcOU0GU68U/staff/${user}`, 10000))
        .then(staff => {
          setType(staff && staff.data.fields.type);
          setAdjectives(staff && staff.data.fields.adjectives);
          setDescription(staff && staff.data.fields.description);

          staff && !staff.data.fields.manuals && setContent(fillContents(user));

          staff && !staff.data.fields.manuals ? setEditing(true) : setEditing(false);
          staff && staff.data.fields.manuals && setManuals(staff.data.fields.manuals);

          setLoading(false);
          setTimeout(false);
        })
        .catch(err => {
          setTimeout(true);
          setLoading(false);
          console.log(err);
        });
    }

    // Get random photo from Unsplash
    async function getImage() {
      // complete && await unsplash.photos.getRandomPhoto({ collections: ['white'] })
      complete && await unsplash.photos.getPhoto('d2wuqguRzZ0')
      .then((res: any) => res.json())
      .then((data: any) => {
        setImage(data && data.urls.regular);
        setColour(data.color);
      });    
    }

    checkUser();
    getType();
    getImage();
  }, [user, complete]);

  useEffect(() => {
    // Get users manual sections
    async function getManuals() {
      let requests = manuals && manuals.map((m: any) => {
        return axios(headers('get', `https://api.airtable.com/v0/${baseId}/manuals/${m}`, 5000))
      });

      // Complete all the manual requests
      requests && await Promise.all(requests)
        .then((res: any) => {
          let sections: any = [];

          for (let s = 0; s < res.length; s++) {
            sections.push(res[s].data);
          }

          for (let s = res.length; s < 5; s++) {
            sections.push(defaultContent(s + 1, user));
          }

          setContent(sections);
          setLoading(false);
          setTimeout(false);
        })
        .catch(err => {
          setTimeout(true);
          setLoading(false);
          console.log(err);
        });
    }

    getManuals();
  }, [manuals, user]);

  return (
    <IonPage className="personality-page">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Personality</IonTitle>
          {!editing && <IonButtons slot="primary">
            <IonIcon icon={pencil} color="light" onClick={(e) => { setEditing(true); }} />
          </IonButtons>}
          {editing && <IonButtons slot="primary">
            <IonIcon icon={checkmark} color="light" onClick={(e: any) => { saveManual(setErrors, setEditing, user, content) }} />
          </IonButtons>}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {!loading && timeout && <NoInternet />}
        {!timeout && editing && <div className="personality editing">
          {!timeout && loading && <IonSpinner name="dots" />}
          {!loading && type && <img src={`https://api.adorable.io/avatars/285/${type.toLowerCase()}@1000heads.png`} alt={type} />}
          <h3 className="type">{!loading && (type ? type : 'Not set, please take the test on Crystal')}</h3>
          <p className="adjectives">{!loading && adjectives}</p>
          <p className="description">{!loading && description}.</p>
          <h3 className="user-manual">Create user manual</h3>
          <p>You’ve got your personality type, but the best judge of how you work, is you.</p>
          <p>Based on everything you’ve discovered, craft 3-5 points on what someone needs to know about you for a harmonious and impactful professional relationship.</p>
          <p>Swipe left to complete all the sections, and then SAVE.</p>
          {errors && errors.length > 0 && <p className="errors">You have moo-sed some fields, please check all sections!</p>}
          {!timeout && !loading && content.length > 0 && <IonSlides pager={true} options={slideOpts}>
            {
              content.slice(0, 3).map((item: any, index: number) => {
                return (
                  <Slide key={index} item={item} content={content} headline={item.fields.headline} description={item.fields.description} required={true} />
                )
              })
            }
            {
              content.slice(3, 5).map((item: any, index: number) => {
                return (
                  <Slide key={index} item={item} content={content} headline={item.fields.headline} description={item.fields.description} required={false} />
                )
              })
            }
            <SaveSlide setErrors={setErrors} setEditing={setEditing} user={user} content={content} />
          </IonSlides>
          }
        </div>}
        {!timeout && !editing && <div className="personality saved" style={{ backgroundImage: !editing ? `url(${image})` : `` }}>
          <div className="content">            
            {!loading && type && <img src={`https://api.adorable.io/avatars/285/${type.toLowerCase()}@1000heads.png`} alt={type} />}
            <h3 className="type">{!loading && (type ? type : 'Not set, please take the test on Crystal')}</h3>
            <p className="adjectives">{!loading && adjectives}</p>
            <p className="description">{!loading && description}.</p>
            <h3 className="user-manual">Your user manual</h3>
            {loading && <IonSpinner name="dots" />}
            {content && content.length > 0 && content.map((section: any, index: number) => {
              return (
                <div className="section-block" key={index}>
                  <p className="section">{section.fields.headline}</p>
                  <p className="description">{section.fields.description}</p>
                </div>
              )
            })}
          </div>
        </div>}
        {!timeout && complete && <LoginModal show={!user ? true : false} />}
      </IonContent>
    </IonPage>
  );
};

export default Personality;
