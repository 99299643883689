import React, { useState } from 'react';
import { IonSpinner } from '@ionic/react';

import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

import { googleApiKey } from '../config.js';

import './GoogleMap.css';

interface MapProps {
	location: any;
}

const MyMapComponent = withScriptjs(withGoogleMap((props: any) =>
	<GoogleMap
		defaultZoom={props.zoom}
		defaultCenter={props.location ? props.location : props.center}
	>
		{props.isMarkerShown && <Marker position={{ lat: props.location ? props.location.lat : props.center.lat, lng: props.location ? props.location.lng : props.center.lng }} />}
	</GoogleMap>
));

const Map: React.FC<MapProps> = ({ location }) => {
	const [center, setCenter] = useState({
		lat: 51.511969,
		lng: -0.127583
	});
	const [zoom, setZoom] = useState(18);
	const [isMarkerShown, setIsMarkerShown] = useState(true);

	return (
		<div className="map">
			<MyMapComponent 
				zoom={zoom} 
				location={{
					lat: 51.511969,
					lng: -0.127583
				}}
				center={center}
				isMarkerShown={true} 
				googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&v=3.exp`}
				loadingElement={<IonSpinner name="dots" />}
				containerElement={<div style={{ height: `60vh` }} />}
				mapElement={<div style={{ height: `100%` }} />}
			/>
		</div>
	);
};

export default Map;
