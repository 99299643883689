import React, { useState, useEffect } from 'react';
import { IonSpinner, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton } from '@ionic/react';
import { Plugins } from '@capacitor/core';
import LoginModal from '../components/LoginModal';
import NoInternet from '../components/NoInternet';

import './Profile.css';

import axios from 'axios';

import { apiKey, baseId } from '../config.js';

const { Storage } = Plugins;

const headers = (method: any, url: string, timeout: number = 5000) => {
  return {
    method: method,
    headers: {
      Authorization: `Bearer ${apiKey}`
    },
    url: url,
    timeout: timeout
  }
};

const Profile: React.FC = () => {
  const [user, setUser] = useState();
  const [complete, setComplete] = useState();
  const [profile, setProfile] = useState();
  const [loading, setLoading] = useState(true);
  const [timeout, setTimeout] = useState(false);

  useEffect(() => {
    // Look for user in local storage
    async function checkUser() {
      await Storage.get({ key: 'user' })
        .then((res: any) => {
          setUser(res.value);
          setComplete(true);
        })
        .catch((err) => {
          setComplete(true);
          console.log(err);
        });
    }

    // Get profile based on local user ID
    async function getProfile() {
      complete && await axios(headers('get', `https://api.airtable.com/v0/${baseId}/staff/${user}`, 10000))
        .then(staff => {
          staff.data && axios.all([
            axios(headers('get', `https://api.airtable.com/v0/${baseId}/teams/${staff.data.fields.team[0]}`, 5000)),
            axios(headers('get', `https://api.airtable.com/v0/${baseId}/departments/${staff.data.fields.department[0]}`, 5000))
          ])
            .then(axios.spread((team, department) => {
              setProfile({
                name: staff && staff.data.fields.name,
                team: team && team.data.fields.name,
                department: department && department.data.fields.name
              });

              setLoading(false);
              setTimeout(false);
            }))
            .catch(err => {
              setTimeout(true);
              setLoading(false);
              console.log(err);
            });
        })
        .catch(err => {
          setTimeout(true);
          setLoading(false);
          console.log(err);
        });
    }

    checkUser();
    getProfile();
  }, [user, complete]);

  return (
    <IonPage className="profile-page">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Profile</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {timeout && !loading && <NoInternet />}
        {!timeout && loading && <IonSpinner name="dots" />}
        <div className="profile-container">
          <p>Name: {profile && !profile.error && profile.name}</p>
          <p>Team: {profile && !profile.error && profile.team}</p>
          <p>Department: {profile && !profile.error && profile.department}</p>
        </div>
        <IonButton onClick={() => {
          Storage.clear();
          window.location.href = "/schedule";
        }}>Logout</IonButton>
        {!timeout && complete && <LoginModal show={!user ? true : false} />}
      </IonContent>
    </IonPage>
  );
};

export default Profile;
