import React, { useState, useEffect } from 'react';
import { IonAlert, IonSelect, IonSelectOption, IonModal, IonButton } from '@ionic/react';
import { Plugins } from '@capacitor/core';

import axios from 'axios';

import './LoginModal.css';

import { apiKey, baseId } from '../config.js';

const { Storage } = Plugins;

const headers = (method: any, url: string, timeout: number = 5000) => {
  return {
    method: method,
    headers: {
      Authorization: `Bearer ${apiKey}`
    },
    url: url,
    timeout: timeout
  }
};

interface ModalProps {
	show: boolean;
}

const compareWith = (o1: any, o2: any) => {
  return o1 && o2 ? o1.user === o2.user : o1 === o2;
};

const LoginModal: React.FC<ModalProps> = ({ show }) => {
	const [staff, setStaff] = useState([]);
	const [user, setUser] = useState('');
	const [team, setTeam] = useState('');
	const [showModal, setShowModal] = useState(show);
	const [showAlert, setShowAlert] = useState(false);

	useEffect(() => {
		async function getStaff() {
			await axios(headers('get', `https://api.airtable.com/v0/${baseId}/staff`, 5000))
				.then((results: any) => {
					setStaff(results.data.records.sort((a: any, b: any) => a.fields.name > b.fields.name ? 1 : -1));
				})
				.catch((err: any) => {
					console.log(err);
				});
		}

		getStaff();
	}, [])

	return (
		<IonModal isOpen={showModal} backdropDismiss={false}>
			<IonAlert
			isOpen={showAlert}
			onDidDismiss={() => setShowAlert(false)}
			header={'Hey'}
			subHeader={'You are not No-one'}
			message={'Please select your name'}
			buttons={['OH, FINE']}
			/>
			<img src="assets/images/owl-icon.png" alt="Headucation Owl" className="owl" />
			<IonSelect placeholder="Who are you?" onIonChange={(e) => { setUser(e.detail.value.user); setTeam(e.detail.value.team); }} compareWith={compareWith}>
				{
					staff && staff
						.map((s: any) => {
							return (
								<IonSelectOption value={{ user: s.id, team: s.fields.team && s.fields.team[0] }} key={s.id}>{s.fields.name && s.fields.name}</IonSelectOption>
							)
						})
				}
			</IonSelect>
			<IonButton onClick={() => { 
				if (user !== '') {
					Storage.set({ key: 'user', value: user });
					Storage.set({ key: 'team', value: team });
					setShowModal(false);
					window.location.href = "/schedule";
				} else {
					setShowAlert(true);
				}
			}} >That's me!</IonButton>
		</IonModal>
	);
};

export default LoginModal;
